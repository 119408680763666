import { Map } from 'ol';
import { Coordinate, distance } from 'ol/coordinate';
import {
  boundingExtent,
  equals as checkEqualityOfTwoExtents,
  intersects as checkInteractivityOfTwoExtents,
  containsExtent,
  createEmpty as createEmptyExtent,
  extend as enlargeExtent,
  getBottomLeft,
  getBottomRight,
  getCenter,
  getTopLeft,
  getTopRight,
} from 'ol/extent';
import { fromExtent as createPolygonFromExtent } from 'ol/geom/Polygon';
import { Projection, getPointResolution, transformExtent } from 'ol/proj';
import {
  fromLonLat,
  legacyLatLngToLonLat,
  lonLatToLegacyLatLng,
  normalizeLatitude,
  normalizeLongitude,
  toLonLat,
} from './coordinate';
import type { Extent, LegacyBounds } from './types';
import { getMapProjection, getMapResolution } from './view';

export {
  checkEqualityOfTwoExtents,
  checkInteractivityOfTwoExtents,
  containsExtent,
  getCenter as getExtentCenter,
  transformExtent,
};

export const WGS84_WORLD_EXTENT = Object.freeze([-180, -90, 180, 90]);

export function padExtent(extent: Extent, padding: number): Extent {
  const polygon = createPolygonFromExtent(extent);
  polygon.scale(1 + padding);
  return polygon.getExtent();
}

export function padExtentByMeters(
  map: Map,
  extent: Extent,
  meters: number
): Extent {
  const projection = getMapProjection(map);
  const mpu = projection.getMetersPerUnit();
  if (!mpu) {
    throw 'Unsupported operation.';
  }
  const resolution = getMapResolution(map);

  const bottomLeft = getBottomLeft(extent);
  const bottomLeftR =
    getPointResolution(projection, resolution, bottomLeft) * mpu;
  const bottomLeftPixel = map.getPixelFromCoordinate(bottomLeft);
  const offset1 = meters / bottomLeftR;
  const newBottomLeft = map.getCoordinateFromPixel([
    bottomLeftPixel[0] - offset1,
    bottomLeftPixel[1] + offset1,
  ]);

  const topRight = getTopRight(extent);
  const topRightR = getPointResolution(projection, resolution, topRight) * mpu;
  const topRightPixel = map.getPixelFromCoordinate(topRight);
  const offset2 = meters / topRightR;
  const newTopRight = map.getCoordinateFromPixel([
    topRightPixel[0] + offset2,
    topRightPixel[1] - offset2,
  ]);

  return boundingExtent([newBottomLeft, newTopRight]);
}

// A bounds is expressed by a pair of diagonal LatLngs.
export function extentToBounds(
  extent: Extent,
  projection: Projection,
  padding = 0
): LegacyBounds {
  if (padding > 0) {
    extent = padExtent(extent, padding);
  }

  const bottomLeft = getBottomLeft(extent);
  const topRight = getTopRight(extent);

  return {
    _southWest: lonLatToLegacyLatLng(toLonLat(bottomLeft, projection)),
    _northEast: lonLatToLegacyLatLng(toLonLat(topRight, projection)),
  };
}

export function boundsToExtent(
  bounds: LegacyBounds,
  projection: Projection,
  padding = 0
): Extent {
  const coordinates: Coordinate[] = [];

  if ('_southWest' in bounds) {
    const { _southWest } = bounds;
    const bottomLeft = fromLonLat(legacyLatLngToLonLat(_southWest), projection);
    coordinates.push(bottomLeft);
  }

  if ('_southEast' in bounds) {
    const { _southEast } = bounds;
    const bottomRight = fromLonLat(
      legacyLatLngToLonLat(_southEast),
      projection
    );
    coordinates.push(bottomRight);
  }

  if ('_northEast' in bounds) {
    const { _northEast } = bounds;
    const topRight = fromLonLat(legacyLatLngToLonLat(_northEast), projection);
    coordinates.push(topRight);
  }

  if ('_northWest' in bounds) {
    const { _northWest } = bounds;
    const topLeft = fromLonLat(legacyLatLngToLonLat(_northWest), projection);
    coordinates.push(topLeft);
  }

  let extent = boundingExtent(coordinates);

  if (padding > 0) {
    extent = padExtent(extent, padding);
  }

  return extent;
}

export function transformBounds(
  bounds: LegacyBounds,
  projection: Projection
): LegacyBounds {
  const extent = boundsToExtent(bounds, projection);
  const topLeft = getTopLeft(extent);
  const bottomRight = getBottomRight(extent);

  return {
    _northWest: lonLatToLegacyLatLng(toLonLat(topLeft, projection)),
    _southEast: lonLatToLegacyLatLng(toLonLat(bottomRight, projection)),
  };
}

export function mergeExtents(...extents: Extent[]): Extent {
  let newExtent = createEmptyExtent();

  extents.forEach((extent) => {
    newExtent = enlargeExtent(newExtent, extent);
  });

  return newExtent;
}

export function transformEsriServiceLayerExtent(
  esriServiceLayerExtent
): Extent {
  const {
    xmin,
    ymin,
    xmax,
    ymax,
    spatialReference: { wkid: specWkid, latestWkid: specLatestWkid },
  } = esriServiceLayerExtent;
  let wkid = specLatestWkid;
  if (typeof wkid !== 'number') {
    wkid = specWkid;
  }
  if (wkid === 102100) {
    wkid = 3857;
  }

  try {
    return transformExtent(
      [xmin, ymin, xmax, ymax],
      `EPSG:${wkid}`,
      'EPSG:4326'
    );
  } catch (e) {
    throw `The coordinate styem 'EPSG:${wkid}' is not supported.`;
  }
}

export function checkEqualityOfTwoExtentsLeniently(
  extent1: Extent,
  extent2: Extent,
  precision = 8
): boolean {
  const handleValue = (value) =>
    Math.round(value * Math.pow(10, precision)) / precision;
  extent1 = [...extent1].map(handleValue);
  extent2 = [...extent2].map(handleValue);
  return (
    extent1[0] == extent2[0] &&
    extent1[2] == extent2[2] &&
    extent1[1] == extent2[1] &&
    extent1[3] == extent2[3]
  );
}

export function getDiagonalLineDisplayLength(map: Map, extent: Extent): number {
  const topLeft = getTopLeft(extent);
  const bottomRight = getBottomRight(extent);
  const topLeftPixel = map.getPixelFromCoordinate(topLeft);
  const bottomRightPixel = map.getPixelFromCoordinate(bottomRight);
  return distance(topLeftPixel, bottomRightPixel);
}

export function normalizeExtent(extent) {
  let [minx, miny, maxx, maxy] = extent;
  minx = normalizeLongitude(minx);
  miny = normalizeLatitude(miny);
  maxx = normalizeLongitude(maxx);
  maxy = normalizeLatitude(maxy);
  if (minx > maxx) {
    [minx, maxx] = [maxx, minx];
  }
  if (miny > maxy) {
    [miny, maxy] = [maxy, miny];
  }
  return [minx, miny, maxx, maxy];
}
