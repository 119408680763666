import { produce, setAutoFreeze } from 'immer';
import _cloneDeepWith from 'lodash/cloneDeepWith';
import _isEmpty from 'lodash/isEmpty';
import type {
  ChemicalGroup,
  OtherChemicalGroup,
  SampleChemical,
} from './evalu8';
import { getChemicalIdsOfGroup } from './evalu8';
import * as layout from './layout';

export function preprocessFigure(
  figure,
  allChemicalGroups: (ChemicalGroup | OtherChemicalGroup)[],
  allChemicals: SampleChemical[],
  isChemicalsReady: boolean = true
) {
  setAutoFreeze(false);
  try {
    return produce(figure, (draft) => {
      const { builtin_callouts_by_sample } = draft;
      if (
        builtin_callouts_by_sample &&
        Array.isArray(builtin_callouts_by_sample)
      ) {
        draft.builtin_callouts_by_sample = builtin_callouts_by_sample.reduce(
          (accu, item, index) => {
            if (item) {
              accu[index] = item;
            }
            return accu;
          },
          {}
        );
      }

      if (typeof draft.scenarios === 'string') {
        try {
          draft.scenarios = JSON.parse(draft.scenarios);
        } catch (e) {
          console.error(
            `Failed to parse the scenarios of the figure with id ${draft.id}`
          );
          draft.enviro_callout_filter = null;
        }
      }

      if (typeof draft.enviro_callout_filter === 'string') {
        try {
          draft.enviro_callout_filter = JSON.parse(draft.enviro_callout_filter);
        } catch (e) {
          console.error(
            `Failed to parse the enviro_callout_filter of the figure with id ${draft.id}`
          );
          draft.enviro_callout_filter = null;
        }
      }

      const { enviro_callout_filter } = draft;
      if (enviro_callout_filter && isChemicalsReady) {
        const { selectedChemicalsByGroup } = enviro_callout_filter;
        const isEmpty =
          !selectedChemicalsByGroup ||
          (Array.isArray(selectedChemicalsByGroup) &&
            _isEmpty(selectedChemicalsByGroup));
        draft.enviro_callout_filter.selectedChemicalsByGroup = isEmpty
          ? {}
          : Object.keys(selectedChemicalsByGroup).reduce((accu, k) => {
              const chemicalGroupId = parseInt(k, 10);
              const chemicalGroup = allChemicalGroups.find(
                (cg) => cg.id === chemicalGroupId
              );
              if (chemicalGroup) {
                const chemicalIds = getChemicalIdsOfGroup(
                  chemicalGroup,
                  allChemicalGroups,
                  allChemicals
                );
                accu[k] = selectedChemicalsByGroup[k].filter((chemicalId) =>
                  chemicalIds.includes(chemicalId)
                );
              }
              return accu;
            }, {});
      }
    });
  } finally {
    setAutoFreeze(true);
  }
}

export function checkIsSampleExceedancePlan(figure) {
  const { scenarios, chemicals } = figure;
  return !chemicals && scenarios;
}

export function checkIsSpecificChemicalPlan(figure) {
  const { scenarios, chemicals } = figure;
  return !!(scenarios && chemicals);
}

export function checkIsGatherCalloutEnabled(figure) {
  if (!figure.gather_callout_settings) {
    return [];
  }

  const {
    gather_callout_settings: { selectedFieldsByTab },
  } = figure;
  return !_isEmpty(selectedFieldsByTab);
}

export function convertCalloutSettings(settings) {
  return _cloneDeepWith(
    {
      selectedFieldsByTab: {},
      isSectionHeaderVisibleByTab: {},
      ...settings,
    },
    (value, key) => {
      if (
        ['selectedFieldsByTab', 'isSectionHeaderVisibleByTab'].includes(key) &&
        Array.isArray(value) &&
        _isEmpty(value)
      ) {
        return {};
      }
    }
  );
}

export function updateFontSizeOfBuiltinCalloutsBySample(
  builtinCalloutsBySample,
  fontSize
) {
  return Object.keys(builtinCalloutsBySample).reduce((accu, sampleId) => {
    const builtinCallouts = builtinCalloutsBySample[sampleId];
    return {
      ...accu,
      [sampleId]: builtinCallouts.map((item) => ({
        ...item,
        geojson: {
          ...item.geojson,
          properties: {
            ...item.geojson.properties,
            fontSize,
          },
        },
      })),
    };
  }, {});
}

export function updateBuiltinCalloutsBySample(
  builtinCalloutsBySample,
  sampleId,
  builtinCallout
) {
  const builtinCallouts = builtinCalloutsBySample[sampleId] ?? [];
  const nextBuiltinCallouts = [...builtinCallouts];
  const builtinCalloutIndex = nextBuiltinCallouts.findIndex(
    (item) =>
      item.geojson.properties.contentType ===
      builtinCallout.geojson.properties.contentType
  );
  if (builtinCalloutIndex === -1) {
    nextBuiltinCallouts.push(builtinCallout);
  } else {
    nextBuiltinCallouts.splice(builtinCalloutIndex, 1, builtinCallout);
  }
  return {
    ...builtinCalloutsBySample,
    [sampleId]: nextBuiltinCallouts,
  };
}

export function checkIsBuiltinCalloutPersistent(
  builtinCalloutsBySample,
  sampleId,
  contentType
) {
  return !!builtinCalloutsBySample[sampleId]?.some(
    (item) => item.geojson.properties.contentType === contentType
  );
}

export type FigureSettings = {
  layout: layout.FigureLayout;
};

export type UpdateFigureSettingsPayload = (draft: FigureSettings) => void;

export function roundFigureViewScale(value: number): number {
  return Math.round(value);
}

export function formatFigureViewScale(value: number): string {
  if (!value) {
    return 'Auto';
  }

  return `1:${roundFigureViewScale(value)}`;
}
